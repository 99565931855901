import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

import avatarImg from "../images/avatar.png"
import { media } from "../styles"

const S = {}
S.SocialIcon = styled.a`
  transition-property: fill;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;

  :hover {
    fill: #f5f5f5;
  }
`
S.Avatar = styled.img`
  float: none;
  border-radius: 2px;
  margin-bottom: 2rem;
  width: 235px;
  height: 235px;
  ${media.laptop`
    float: left;
    margin-right: 3rem;
    margin-bottom: 2rem;
  `};
`
S.SocialIconsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 235px;
`

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Hello" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "85vh",
          justifyContent: "space-evenly",
        }}
      >
        <div>
          <h1 style={{ margin: 0 }}>Hello</h1>
        </div>
        <div>
          <S.Avatar
            src={avatarImg}
            alt="Marcin Gębala"
            width={235}
            height={235}
          />
          <p style={{ textAlign: "justify", overflowWrap: "break-word" }}>
            My name is Marcin Gębala and I'm a software engineer who specializes
            in web applications. I'm a Principal Developer at{" "}
            <a
              href="https://saleor.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              Saleor Commerce
            </a>
            , where I lead the backend team responsible for the core GraphQL
            API. I love sharing my knowledge, and you may occasionally find me
            speaking at tech events around the world. In my free time, I enjoy
            running, making music, and traveling. I'm based in Wrocław, Poland.
          </p>
        </div>
        <div style={{ border: "0px blue solid" }}>
          <S.SocialIconsDiv>
            <S.SocialIcon
              href="https://twitter.com/maarcingebala"
              target="_blank"
              rel="noopener noreferrer"
              title="Visit my Twitter profile"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="32"
                height="32"
                viewBox="0 0 32 32"
              >
                <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"></path>
              </svg>
            </S.SocialIcon>
            <S.SocialIcon
              href="https://github.com/maarcingebala/"
              target="_blank"
              rel="noopener noreferrer"
              title="Visit my GitHub profile"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="32"
                height="32"
                viewBox="0 0 32 32"
              >
                <path
                  fill-rule="evenodd"
                  d="M 16 4 C 9.371094 4 4 9.371094 4 16 C 4 21.300781 7.4375 25.800781 12.207031 27.386719 C 12.808594 27.496094 13.027344 27.128906 13.027344 26.808594 C 13.027344 26.523438 13.015625 25.769531 13.011719 24.769531 C 9.671875 25.492188 8.96875 23.160156 8.96875 23.160156 C 8.421875 21.773438 7.636719 21.402344 7.636719 21.402344 C 6.546875 20.660156 7.71875 20.675781 7.71875 20.675781 C 8.921875 20.761719 9.554688 21.910156 9.554688 21.910156 C 10.625 23.746094 12.363281 23.214844 13.046875 22.910156 C 13.15625 22.132813 13.46875 21.605469 13.808594 21.304688 C 11.144531 21.003906 8.34375 19.972656 8.34375 15.375 C 8.34375 14.0625 8.8125 12.992188 9.578125 12.152344 C 9.457031 11.851563 9.042969 10.628906 9.695313 8.976563 C 9.695313 8.976563 10.703125 8.65625 12.996094 10.207031 C 13.953125 9.941406 14.980469 9.808594 16 9.804688 C 17.019531 9.808594 18.046875 9.941406 19.003906 10.207031 C 21.296875 8.65625 22.300781 8.976563 22.300781 8.976563 C 22.957031 10.628906 22.546875 11.851563 22.421875 12.152344 C 23.191406 12.992188 23.652344 14.0625 23.652344 15.375 C 23.652344 19.984375 20.847656 20.996094 18.175781 21.296875 C 18.605469 21.664063 18.988281 22.398438 18.988281 23.515625 C 18.988281 25.121094 18.976563 26.414063 18.976563 26.808594 C 18.976563 27.128906 19.191406 27.503906 19.800781 27.386719 C 24.566406 25.796875 28 21.300781 28 16 C 28 9.371094 22.628906 4 16 4 Z"
                ></path>
              </svg>
            </S.SocialIcon>
            <S.SocialIcon
              href="https://www.linkedin.com/in/marcin-gebala-4a888599/"
              target="_blank"
              rel="noopener noreferrer"
              title="Visit my LinkedIn profile"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="32"
                height="32"
                viewBox="0 0 24 24"
              >
                <path d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M9,17H6.477v-7H9 V17z M7.694,8.717c-0.771,0-1.286-0.514-1.286-1.2s0.514-1.2,1.371-1.2c0.771,0,1.286,0.514,1.286,1.2S8.551,8.717,7.694,8.717z M18,17h-2.442v-3.826c0-1.058-0.651-1.302-0.895-1.302s-1.058,0.163-1.058,1.302c0,0.163,0,3.826,0,3.826h-2.523v-7h2.523v0.977 C13.93,10.407,14.581,10,15.802,10C17.023,10,18,10.977,18,13.174V17z"></path>
              </svg>
            </S.SocialIcon>
            <S.SocialIcon
              href="mailto:hello@marcingebala.com"
              title="Drop me an email"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 24 24"
              >
                <path d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z" />
              </svg>
            </S.SocialIcon>
          </S.SocialIconsDiv>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
